import axios from "axios";

import animationInit from './animation';

const headerInit = ()=> {

    const { slideUp, slideDown } = animationInit();

    const menuTriggerHandle = () => {

        const headerElm = document.querySelector('.header');
        const menuTriggerElm = document.querySelector('[data-menu-trigger]');
        const headerMenuMobileElm = document.querySelector('.header__menu_block');
        const headerMenuWrapElm = document.querySelector('.header__menu_wrap');
        const headerMenuBackdropElm = document.querySelector('.header__menu_backdrop');

        const headerSearchTriggerElm = document.querySelector('.header__search-trigger');
        const headerSearchBlockElm = document.querySelector('.header__search-block');

        menuTriggerElm.addEventListener('click', (e) => {
            e.preventDefault(); e.stopPropagation();

            if (menuTriggerElm.classList.contains('open')) {

                [headerElm, menuTriggerElm, headerMenuMobileElm, headerMenuBackdropElm].forEach((elm) => {
                    elm.classList.remove('open');
                });

                headerMenuBackdropElm.classList.remove('z-index');
                // headerMenuWrapElm.style.height = 0;
                slideUp(headerMenuWrapElm, 300)
            } else {
                [headerElm, menuTriggerElm, headerMenuMobileElm, headerMenuBackdropElm].forEach((elm) => {
                    elm.classList.add('open');
                });

                headerMenuBackdropElm.classList.add('z-index');

                [headerSearchTriggerElm, headerSearchBlockElm].forEach((elm) => {
                    elm.classList.remove('open');
                });
                // headerMenuWrapElm.style.height = headerMenuWrapElm.scrollHeight + 'px';
                slideDown(headerMenuWrapElm, 300)
            }
        });

        headerMenuMobileElm.addEventListener('click', (e) => {
            if (e.target === headerMenuMobileElm) {
                e.preventDefault(); e.stopPropagation();
                [menuTriggerElm, headerMenuMobileElm, headerMenuBackdropElm].forEach((elm) => {
                    elm.classList.remove('open');
                });
                slideUp(headerMenuWrapElm, 300)
            }
        });

    }

    const menuItemHandle = () => {

        const menuItemElms = document.querySelectorAll('.menu_mobile-item');
        const menuTriggerElms = document.querySelectorAll('.menu_mobile-trigger');
        const menuLinkElms = document.querySelectorAll('.menu_mobile-link');
        // const menuElms = document.querySelectorAll('.menu_mobile-list');

        menuTriggerElms.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault(); e.stopPropagation();

                const menuItemElm = item.parentElement;
                const menuListElm = menuItemElm.querySelector('.menu_mobile-list');

                if (menuItemElm.classList.contains('open')) {
                    // console.log('111aaa')
                    menuItemElm.classList.remove('open');
                    slideUp(menuListElm, 300)
                } else {
                    // console.log('222aaa')
                    menuItemElm.classList.add('open');
                    slideDown(menuListElm, 300)

                    const parentElm = item.parentNode;
                    if(parentElm.classList.contains('with-submenu')) {
                        menuItemElms.forEach((menuItemElm) => {
                            // console.log('menuItemElm', menuItemElm)
                            if (menuItemElm !== item.parentElement && menuItemElm.classList.contains('open') && menuItemElm.classList.contains('menu_mobile-item')) {
                                menuItemElm.classList.remove('open');
                                slideUp(menuItemElm.querySelector('.menu_mobile-list'), 300)
                            }
                        })
                    }
                }
            });
        });

        menuLinkElms.forEach((item) => {
            item.addEventListener('click', (e) => {
                const windowWidth = window.innerWidth;
                if (windowWidth < 1200) {
                    const menuItemElm = item.parentElement;
                    const menuListElm = menuItemElm.querySelector('.menu_mobile-list');

                    if (menuItemElm.classList.contains('open')) {
                        if (item.getAttribute("href") === '#' ) {
                            menuItemElm.classList.remove('open');
                            slideUp(menuListElm, 300)
                            e.preventDefault(); e.stopPropagation();
                        }
                    } else {
                        menuItemElm.classList.add('open');
                        slideDown(menuListElm, 300)

                        menuItemElms.forEach((menuItemElm) => {
                            if (menuItemElm !== item.parentElement && menuItemElm.classList.contains('open')) {
                                menuItemElm.classList.remove('open');
                                slideUp(menuItemElm.querySelector('.menu_mobile-list'), 300)
                            }
                        })
                        e.preventDefault(); e.stopPropagation();
                    }
                }
            });
        });

        menuItemElms.forEach((item) => {
            item.addEventListener('mouseenter', (e) => {
                const windowWidth = window.innerWidth;
                if (windowWidth > 1200) {
                    item.classList.add('open');
                }
            });
            item.addEventListener('mouseleave', (e) => {
                const windowWidth = window.innerWidth;
                if (windowWidth > 1200) {
                    item.classList.remove('open');
                }
            });
        });


        // document.addEventListener("keydown", function(e) {
        //     menuItemElms.forEach((item) => {
        //         if ( item.classList.contains('open')) {
        //             slideUp(item.querySelector('.menu_mobile-list'), 300)
        //         }
        //     })
        // });

    }

    const headerSearchHandle = () => {

        const headerElm = document.querySelector('.header');

        const headerSearchTriggerElm = document.querySelector('.header__search-trigger');
        const headerSearchResultBlockElm = document.querySelector('.header-search-results-block');
        const headerSearchResultElm = document.querySelector('.header-search-results');
        const headerSearchBlockElm = document.querySelector('.header__search-block');
        const headerSearchInputElm = headerSearchBlockElm.querySelector('input');
        const headerMenuBackdropElm = document.querySelector('.header__menu_backdrop');
        const headerSearchResultsLinkElm = document.querySelector('.header-search-results-link');

        const menuTriggerElm = document.querySelector('[data-menu-trigger]');
        const headerMenuMobileElm = document.querySelector('.header__menu_block');

        headerSearchTriggerElm.addEventListener('click', (e) => {
            e.preventDefault(); e.stopPropagation();

            if (headerSearchTriggerElm.classList.contains('open')) {
                [headerElm, headerSearchTriggerElm, headerSearchBlockElm, headerMenuBackdropElm].forEach((elm) => {
                    elm.classList.remove('open');
                });
                headerMenuBackdropElm.classList.remove('z-index')
            } else {
                [headerElm, headerSearchTriggerElm, headerSearchBlockElm, headerMenuBackdropElm].forEach((elm) => {
                    elm.classList.add('open');
                });
                headerMenuBackdropElm.classList.add('z-index')

                menuTriggerElm.classList.remove('open');
                headerMenuMobileElm.classList.remove('open');
            }

            headerSearchInputElm.focus();
        });

        headerSearchResultElm.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        headerSearchResultBlockElm.addEventListener('click', (e) => {
            e.stopPropagation();
            [headerElm, headerSearchTriggerElm, headerSearchBlockElm, headerMenuBackdropElm].forEach((elm) => {
                elm.classList.remove('open');
            });
        });

        let searchTimeout;

        document.addEventListener('click', function(event) {
            const targetHeaderSearchElm = event.target.closest('.header__search-block');
            if (!targetHeaderSearchElm) {
                if (headerSearchResultBlockElm.classList.contains('open')) {
                    headerSearchResultBlockElm.classList.remove('open');
                    headerSearchResultElm.innerHTML = '';
                    headerSearchResultsLinkElm.style.display = 'none';
                }
            }
        });

        headerSearchInputElm.addEventListener('keyup', (e) => {
            if (e.key === 'Escape' || e.key === 'Esc') {
                [headerSearchTriggerElm, headerSearchBlockElm, headerMenuBackdropElm].forEach((elm) => {
                    elm.classList.remove('open');
                });
            } else {
                [headerSearchTriggerElm, headerSearchBlockElm].forEach((elm) => {
                    elm.classList.add('loading');
                });

                if(headerSearchInputElm.value.length > 2) {
                    clearTimeout(searchTimeout);
                    const params = {
                        query: headerSearchInputElm.value
                    }
                    searchTimeout = setTimeout(() => {
                        axios.get((document.documentElement.lang === 'ru' ? '/ru' : '') + '/search', {
                                headers: {
                                    'X-Requested-With': 'XMLHttpRequest',
                                    'Accept-Language': document.documentElement.lang
                                },
                                params
                        })
                            .then((response) => {
                                if (response.data.count > 0) {
                                    // headerSearchResultElm.innerHTML = response.data.data;
                                    // headerSearchResultBlockElm.classList.add('open');
                                    headerSearchResultElm.innerHTML = '';
                                    headerSearchResultsLinkElm.style.display = 'none';

                                    response.data.products.forEach((v) => {
                                        headerSearchResultElm.innerHTML +=
                                            '<div class="search-results-item">' +
                                            '<div class="search-results-item-image">' +
                                            '   <a href="' + v.link + '">\n' +
                                            '     <img src="' + v.image + '">' +
                                            '   </a>\n' +
                                            '</div>\n' +
                                            '<div class="search-results-item-info">\n' +
                                            '    <h3><a href="' + v.link + '">' + v.title + '</a></h3>\n' +
                                            '    <div class="search-results-item-price">' + v.price + '</div>\n' +
                                            '    <div class="search-results-item-size">' + v.size + '</div>\n' +
                                            '   </div>\n' +
                                            '  </div>';
                                    });

                                    if (response.data.count >= 5){
                                        headerSearchResultsLinkElm.style.display = 'block';
                                    }
                                    headerSearchResultBlockElm.classList.add('open');
                                } else {
                                    headerSearchResultElm.innerHTML = '';
                                    headerSearchResultsLinkElm.style.display = 'none';
                                    headerSearchResultBlockElm.classList.remove('open');
                                }

                                [headerSearchTriggerElm, headerSearchBlockElm].forEach((elm) => {
                                    elm.classList.remove('loading');
                                });
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }, 200);
                } else {
                    [headerSearchTriggerElm, headerSearchBlockElm].forEach((elm) => {
                        elm.classList.remove('loading');
                    });
                    headerSearchResultBlockElm.classList.remove('open');
                    headerSearchResultElm.innerHTML = '';
                    headerSearchResultsLinkElm.style.display = 'none';
                }

            }

        });


    }

    menuTriggerHandle();
    menuItemHandle();
    headerSearchHandle();
}

export default headerInit;
